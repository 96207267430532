import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import {NgbModule} from 'node_modules/@ng-bootstrap/ng-bootstrap/bundles/ng-bootstrap.js';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClientComponent } from './Client/client.component';
import { AboutCompanyComponent } from './About/about-company.component';
import { OurteamComponent } from './ourteam/ourteam.component';
import { SoftwareComponent } from './Services/SoftwareDevlopment/software.component';
import { WebAppComponent } from './Services/WebAppliaction/web-app.component';
import { AndroidComponent } from './Services/Android/android.component';
import { WebDesignComponent } from './Services/WebDesign/web-design.component';
import { DigitalMarketingComponent } from './Services/DigitalMarketing/digital-marketing.component';
import { InternshipComponent } from './Services/Internship/internship.component';
import { TrainingComponent } from './Services/TraningInstitude/training.component';
import { FooterComponent } from './Services/Footer/footer.component';
import { ErpComponent } from './Products/ERP/erp.component';
import { CrmComponent } from './Products/CRM/crm.component';
import { ProjectManageComponent } from './Products/ProjectManagement/project-manage.component';
import { HospitalManageComponent } from './Products/HospitalManagement/hospital-manage.component';
import { PosComponent } from './Products/POS/pos.component';
import { SchoolComponent } from './Products/School/school.component';
import { MokComponent } from './Products/Mock/mok.component';
import { MlmSoftwareComponent } from './Products/MLM/mlm-software.component';
import { ContactComponent } from './ContactUs/contact.component';
import { TciComponent } from './Sector/TechcelestialInstitude/tci.component';
import { ItCompanyComponent } from './Sector/TCLCompany/it-company.component';
import { SectorSchoolComponent } from './Sector/School/sector-school.component';

import { HeaderComponent } from './Home/header/header.component';
import { HomeComponent } from './Home//home/home.component';
import { AboutComponent } from './Home//about/about.component';
import { FeaturesComponent } from './Home//features/features.component';
import { ServiceComponent } from './Home/service/service.component';
import { ProjectsComponent } from './Home/projects/projects.component';
import { ManagementComponent } from './Home/management/management.component';
import { HfooterComponent } from './Home/HFooter/hfooter.component';

import { PfooterComponent } from './Products/ProductFooter/pfooter.component';
import { MarketComponent } from './Sector/CelestialMarket/market.component';
import { LeadComponent } from './Services/LeadManagement/lead.component';



@NgModule({
  declarations: [
    AppComponent,
    ClientComponent,
    AboutCompanyComponent,
    OurteamComponent,
    SoftwareComponent,
    WebAppComponent,
    AndroidComponent,
    WebDesignComponent,
    DigitalMarketingComponent,
    InternshipComponent,
    TrainingComponent,
    FooterComponent,
    ErpComponent,
    CrmComponent,
    ProjectManageComponent,
    HospitalManageComponent,
    PosComponent,
    SchoolComponent,
    MokComponent,
    MlmSoftwareComponent,
    ContactComponent,
    TciComponent,
    ItCompanyComponent,
    SectorSchoolComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    FeaturesComponent,
    ManagementComponent,
    ServiceComponent,
    ProjectsComponent,
    HfooterComponent,
    PfooterComponent,
    MarketComponent,
    LeadComponent,
   
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
