import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-manage',
  templateUrl: './project-manage.component.html',
  styleUrls: ['./project-manage.component.css']
})
export class ProjectManageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
