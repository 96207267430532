import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mlm-software',
  templateUrl: './mlm-software.component.html',
  styleUrls: ['./mlm-software.component.css']
})
export class MlmSoftwareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
