import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mok',
  templateUrl: './mok.component.html',
  styleUrls: ['./mok.component.css']
})
export class MokComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
