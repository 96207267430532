import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientComponent } from './Client/client.component';
import { AboutCompanyComponent } from './About/about-company.component';
import { OurteamComponent } from './ourteam/ourteam.component';
import { SoftwareComponent } from './Services/SoftwareDevlopment/software.component';
import { WebAppComponent } from './Services/WebAppliaction/web-app.component';
import { AndroidComponent } from './Services/Android/android.component';
import { WebDesignComponent } from './Services/WebDesign/web-design.component';
import { DigitalMarketingComponent } from './Services/DigitalMarketing/digital-marketing.component';
import { InternshipComponent } from './Services/Internship/internship.component';
import { TrainingComponent } from './Services/TraningInstitude/training.component';
import { ErpComponent } from './Products/ERP/erp.component';
import { CrmComponent } from './Products/CRM/crm.component';
import { ProjectManageComponent } from './Products/ProjectManagement/project-manage.component';
import { HospitalManageComponent } from './Products/HospitalManagement/hospital-manage.component';
import { PosComponent } from './Products/POS/pos.component';
import { SchoolComponent } from './Products/School/school.component';
import { MokComponent } from './Products/Mock/mok.component';
import { MlmSoftwareComponent } from './Products/MLM/mlm-software.component';
import { ContactComponent } from './ContactUs/contact.component';
import { ItCompanyComponent } from './Sector/TCLCompany/it-company.component';
import { SectorSchoolComponent } from './Sector/School/sector-school.component';
import { MarketComponent } from './Sector/CelestialMarket/market.component';


import { HeaderComponent } from './Home/header/header.component';
import { HomeComponent } from './Home//home/home.component';
import { AboutComponent } from './Home/about/about.component';
import { FeaturesComponent } from './Home/features/features.component';
import { ServiceComponent } from './Home/service/service.component';
import { ProjectsComponent } from './Home/projects/projects.component';
import { ManagementComponent } from './Home/management/management.component';
import { HfooterComponent } from './Home/HFooter/hfooter.component';
import { PfooterComponent } from './Products/ProductFooter/pfooter.component';
import { LeadComponent } from './Services/LeadManagement/lead.component';

const routes: Routes = [
  { path: 'client', component: ClientComponent },

  { path: 'about-company', component: AboutCompanyComponent },
  { path: 'ourteam', component: OurteamComponent },

  { path: 'software', component: SoftwareComponent },
  { path: 'web-app', component: WebAppComponent },
  { path: 'android', component: AndroidComponent },
  { path: 'web-design', component: WebDesignComponent },
  { path: 'digital-marketing', component: DigitalMarketingComponent },
  { path: 'internship', component: InternshipComponent },
  { path: 'training', component: TrainingComponent },
  // { path: 'lead', component: LeadComponent },

  { path: 'erp', component: ErpComponent },
  { path: 'crm', component: CrmComponent },
  { path: 'project-manage', component: ProjectManageComponent },
  { path: 'hospital-manage', component: HospitalManageComponent },
  { path: 'pos', component: PosComponent },
  { path: 'school', component: SchoolComponent },
  { path: 'mock', component: MokComponent },
  { path: 'mlm', component: MlmSoftwareComponent },
  { path: 'contact', component: ContactComponent },

  { path: 'it-comapny', component: ItCompanyComponent },
  { path: 'sector-school', component: SectorSchoolComponent },
  { path: 'market', component: MarketComponent },


  { path: 'home', component: HomeComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'about', component: AboutComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'management', component: ManagementComponent },


{path:'pfooter',component:PfooterComponent},

  {path:'',redirectTo:'/home',pathMatch:'full'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
